import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseCrudApi } from './crud';
import { RspndrConfig } from '../@models/common';
import {
  RspndrAlarm,
  RspndrAlarmFilterCriteria,
  RspndrPage,
  RspndrAlarmStats,
  RspndrAudit,
  RspndrSimpleSubscriberDto,
} from '../@models/rspndr';

@Injectable()
export class RspndrAlarmApi extends BaseCrudApi<RspndrAlarm, RspndrAlarmFilterCriteria> {
  constructor(protected config: RspndrConfig, protected http: HttpClient) {
    super('/api/alarms', config, http);
  }

  alarm(alarmId: string): Observable<RspndrAlarm> {
    return this.http.get<RspndrAlarm>(`${this.config.baseUrl + this.basePath}/${alarmId}`);
  }

  audit(alarmId: string): Observable<RspndrAudit[]> {
    return this.http.get<RspndrAudit[]>(`${this.config.baseUrl}/api/audits/alarms/${alarmId}`);
  }

  route(alarmId: string, page: number = 0, size: number = 100): Observable<number[][]> {
    const params = new HttpParams().set('page', `${page}`).set('size', `${size}`);

    return this.http.get<number[][]>(`${this.config.baseUrl + this.basePath}/${alarmId}/route`, {
      params,
    });
  }

  stats(page: RspndrPage<RspndrAlarmFilterCriteria, RspndrAlarm>): Observable<RspndrAlarmStats> {
    return this.http.post<RspndrAlarmStats>(`${this.config.baseUrl + this.basePath}/stats`, page);
  }

  delta(alarmId: string, modifiedAt: number = 0): Observable<RspndrAlarm> {
    const params: HttpParams = new HttpParams().set('modifiedAt', `${modifiedAt}`);
    return this.http.get<RspndrAlarm>(`${this.config.baseUrl + this.basePath}/delta/${alarmId}`, {
      params,
    });
  }

  reportPdf(alarmId: string, language: string): Observable<Blob> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      }),
      responseType: 'blob' as 'json',
    };
    return this.http.get<Blob>(
      `${this.config.baseUrl}/api/alarms/${alarmId}/report/${language}`,
      httpOptions,
    );
  }
  getSubscribers(
    tenantId: string,
    customerNumber: string,
  ): Observable<RspndrSimpleSubscriberDto[]> {
    const params = new HttpParams().set('customerNumber', customerNumber);
    return this.http.get<RspndrSimpleSubscriberDto[]>(
      `${this.config.baseUrl}/v2/subscriber/portal/tenant/${tenantId}`,
      { params },
    );
  }

  uploadSubscriberCsv(file: File, tenantId: string): Observable<void> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<void>(
      `${this.config.baseUrl}/v2/subscriber/portal/tenant/${tenantId}`,
      formData,
    );
  }
}
